<template>
  <div>
    <a-modal @cancel="turnOff" v-model="visible" width="562px" class="login-form" :footer="null" :centered="true">
      <a-tabs :activeKey="active" @change="cutTabHandler">
        <a-tab-pane key="2" tab="个体登录">
          <div style="padding: 5px">
            <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item label="邮箱" prop="email">
                <a-input v-model="form.email" style="width:400px;height: 40px" placeholder="请输入邮箱"/>
              </a-form-model-item>
              <a-form-model-item label="密码" prop="password">
                <template>
                  <a-input-password v-model="form.password" type="password" placeholder="请输入密码" max-length="16" style="width:400px;height: 40px"/>
                </template>
              </a-form-model-item>
              <a-form-model-item>
                <a-button @click.native.prevent="onSubmit(2)"
                          style="margin-left:62px;width: 400px;background: #FF9A02;color: #FFFFFF;height: 50px;">立即登录
                </a-button>
              </a-form-model-item>
            </a-form-model>
            <div style="display:flex;justify-content:space-around;">
              <div><span>还没有账号？</span>
                  <span  class="ad_font" @click="registered()">立即注册</span>
              </div>
              <div class="ad_font" @click="forgetPassShow(2)">忘记密码？</div>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="1" tab="机构登录">
          <div style="padding: 5px">
            <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-form-model-item label="邮箱" prop="email">
                <a-input v-model="form.email" style="width:400px;height: 40px" placeholder="请输入邮箱"/>
              </a-form-model-item>
              <a-form-model-item label="密码" prop="password">
                <template>
                  <a-input-password v-model="form.password" type="password"  max-length="16" placeholder="请输入密码" style="width:400px;height: 40px;"/>
                </template>
              </a-form-model-item>
              <a-form-model-item>
                <a-button @click="onSubmit(1)"
                          style="margin-left:62px;width: 400px;background: #FF9A02;color: #FFFFFF;height: 50px;">立即登录
                </a-button>
              </a-form-model-item>
            </a-form-model>
            <div style="display:flex;justify-content:space-around;">
              <div><span>还没有账号？</span>
                <span  class="ad_font" @click="registered()">立即注册</span>
              </div>
              <div class="ad_font" @click="forgetPassShow(1)">忘记密码？</div>
            </div>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
    <ForgetPass ref="ForgetPass"></ForgetPass>
  </div>
</template>

<script>
import {login} from '../../api/user'
import {setToken, setType,setPermissions} from '../../utils/auth'
import ForgetPass from "./ForgetPass";

export default {
  name: "login",
  components: {ForgetPass},
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请正确填写邮箱'));
      } else {
        if (value !== '') {
          var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if (!reg.test(value)) {
            callback(new Error('请输入有效的邮箱'));
          }
        }
        callback();
      }
    };
    return {
      showSpan:true,
      labelCol: {span: 3},
      wrapperCol: {span: 5},
      form: {
        email: null,
        password: null,
        type: null
      },
      active: "2",
      visible: false,
      rules: {
        email: [{required:true,validator: validateEmail, trigger: 'blur'}],
        password: [{required: true, message: '请输入密码', trigger: 'blur'}],
      }
    }
  },
  methods: {
    forgetPassShow(type) {
      this.$refs.ForgetPass.show(type)
    },
    turnOff() {
      this.$refs.form.resetFields()
    },
    cutTabHandler(activeKey) {
      this.active = activeKey
      this.$refs.form.resetFields()
    },
    onSubmit(type) {
      this.form.type = type
      this.$refs.form.validate(valid => {
        if (valid) {
          login(this.form).then(res => {
            let {token,permissions} = res.data
            setToken(token)
            setType(type)
            setPermissions(permissions);
            this.$refs.form.resetFields()
            this.visible = false
            this.$emit('fetchLoginBtn')
            this.$router.push({path: '/home'})
            this.$message.success('登录成功')
          })
          // this.$store.dispatch('user/login', this.form).then(() => {
          //   this.$refs.form.resetFields()
          //   this.visible = false
          // })
        }
      })

    },
    registered(){
      if (this.showSpan){
        this.$router.push({path:'/registered',query:{'type':this.active}})
      }else {
        this.visible = false;
      }
    },
    showSpanA(){
      this.showSpan=false;
    },
    show(type) {
      if (type !== "0") {this.active = type}
      this.visible = true
    }
  }
}
</script>

<style lang="scss">
.ad_font {
  text-decoration: underline;
  font-size: 16px;
  font-weight: normal;
  color: #333333;
  cursor: pointer;

  .ad_font:hover {
    color: #FF9A02;
  }
}

.login-form {
  .has-error .ant-form-explain, .has-error .ant-form-split {
    color: #f5222d;
    width: 170px;
  }

  .ant-modal-body {
    background-image: url("../../assets/image/long_backgroud.jpg");
    background-size: 100% 100%;
  }

  .ant-form-item-label > label {
    color: #666666 !important;
  }

  .ant-form label {
    font-size: 16px !important;
  }

  .ant-tabs-ink-bar {
    background-color: #FF9A02 !important;
  }

  .ant-tabs-nav .ant-tabs-tab {
    font-size: 22px;
    color: #333333;
  }

  .ant-tabs-nav {
    margin: 0 0 0 125px !important;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #333333 !important;
    font-size: 22px;
  }

  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #333333 !important;
  }
  .ant-input{
    height: 40px;
  }
  .ant-input:hover{
    border-color: #FF9A02 !important;
  }
}

</style>
