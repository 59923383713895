import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: "/home",
        children: [
            {
                path: 'home',
                component: () => import('@/views/home/index'),
                name: 'home',
                meta: {title: '首页'},

            },
            {
                path: 'funds',
                component: () => import('@/views/select/look_money'),
                name: 'funds',
                meta: {title: '找资金',keepAlive:false},
            },
            {
                path: 'specialty',
                component: () => import('@/views/select/technology'),
                name: 'specialty',
                meta: {title: '找技术',keepAlive:false},
            },
            {
                path: 'service',
                component: () => import('@/views/select/vice'),
                name: 'service',
                meta: {title: '找服务',keepAlive:false},
            },
            {
                path: 'site',
                component: () => import('@/views/select/site'),
                name: 'site',
                meta: {title: '找场地',keepAlive:false},
            },
            {
                path: 'expert',
                component: () => import('@/views/select/professor'),
                name: 'expert',
                meta: {title: '找专家',keepAlive:false},
            },
        ]
    },
    {
        path: '/gov',
        component: Layout,
        children: [
            {
                path: 'gov',
                component: () => import('@/views/gov/index'),
                name: 'gov',
                meta: {title: '创新政策'}
            },
        ]
    },
    {
        path: '/science',
        component: Layout,
        children: [
            {
                path: 'science',
                component: () => import('@/views/science/index'),
                name: 'science',
                meta: {title: '科技活动'}
            },
            {
                path: 'addActivity',
                component: () => import('@/views/science/components/addActivity'),
                name: 'addActivity',
                meta: {title: '发布技术/成果'}
            },
        ]
    },
    {
        path: '/demand',
        component: Layout,
        children: [
            {
                path: 'demand',
                component: () => import('@/views/demand/index'),
                name: 'demand',
                meta: {title: '需求中心'}
            },
            {
                path: 'add',
                component: () => import('@/views/demand/components/AddDemand.vue'),
                name: 'add',
                meta: {title: '发布需求'}
            },
        ]
    },
    {
        path: '/registered',
        component: () => import('@/views/registered/heda'),
        name: 'registered',
        meta: {title: '注册',keepAlive:false},
    },
    {
        path: '/addForm',
        component: Layout,
        children: [
            {
                path: 'addServe',
                component: () => import('@/views/components/addServe'),
                name: 'addServe',
                meta: {title: '发布服务'}
            },
            {
                path: 'addTechnological',
                component: () => import('@/views/components/AddTechnological'),
                name: 'addTechnological',
                meta: {title: '发布技术/成果'}
            },
        ]
    },
    {
        path: '/personalCenter',
        component: Layout,
        redirect: "/personalCenter/index",
        children: [
            {
                path: 'index',
                component: () => import('@/views/personalCenter/heda'),
                name: 'index',
                meta: {title: '个人中心',keepAlive:false}
            },
        ]
    },
    {
        path: '/404',
        component: () => import('@/views/404'),
        hidden: true
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    },
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
