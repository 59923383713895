<template>
  <div class="backAccount" >
    <a-modal v-model="visible"  :centered="true" @ok="okHandler" :footer="null">
      <div style="text-align: center;font-size: 16px;">确定要退出当前账号吗？</div>
      <div style="text-align: center;margin-top: 20px">
          <a-button style="margin-right: 30px" @click="visible = false">取消</a-button>
          <a-button style="background: #075fea;color: #FFFFFF" @click="okHandler">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {removePermissions, removeToken} from '../../utils/auth'
import {logout} from '../../api/user'

export default {
  name: "Back",
  data() {
    return {
      visible: false
    }
  },
  methods: {
    okHandler() {
      logout().then(res => {
        this.$message.success(res.msg)
        removeToken()
        removePermissions()
        this.$router.push({path: '/home'})
        this.visible = false
        location.reload()
      })
    },
    show() {
      this.visible = true
    }
  }
}
</script>

<style lang="scss">
.backAccount {
}
</style>
