<template>
  <div class="talent-main">
    <div
        :class="['talent-nav', { 'talent-no-index': this.activeNav !== 'home' }]"
    >
      <img class="talent-bg" src="@/assets/image/index_bg.png" alt=""/>
      <div class="talent-nav-content">
        <div class="talent-nav-top">
          <div class="talent-nav-logo">
            <router-link :to="{ name: 'home'}">
              <img src="@/assets/image/index_logo.png" alt=""/>
            </router-link>
            株洲分市场
          </div>
          <div class="talent-nav-btns" v-if="!ifLogin" style="padding-right: 15px !important;">
            <span @click="login">登录</span> |
            <span @click="registered">注册</span>
          </div>
          <div v-if="ifLogin" >
            <a-popconfirm  @visibleChange="isShowCenter=!isShowCenter">
              <template slot="title">
                <a-button class="button3" @click="Back(1)"><span>我的信息</span></a-button><br/>
                <a-button class="button3" @click="Back(2)"><span>管理中心</span></a-button><br/>
                <a-button class="button3" @click="Back(3)"><span >退出</span></a-button>
              </template>
              <a-button class="talent-nav-btns">个人中心&nbsp;<a-icon  :style="{color: activeNav === 'home'?'#FFFFFF':'#333333'}" :type="!isShowCenter?'down':'right'"/></a-button>
            </a-popconfirm>
<!--            <div class="talent-nav-btns"  @click="isShowCenter=!isShowCenter">
              <span>个人中心&nbsp;<a-icon :type="!isShowCenter?'down':'right'"/></span>
            </div>
            <div class="talent-item-nav-btns" v-show="!isShowCenter">
              <div class="talent-item-nav-btns-top">
              </div>
              <div class="div talent-item-nav-btns-left" @click="Back(1)">

              </div>
              <div class="div" @click="Back(2)" >

              </div>
              <div class="div talent-item-nav-btns-right" @click="Back(3)">
                <span >退出</span>
              </div>
            </div>-->
          </div>
        </div>
        <div class="talent-nav-search">
          <a-input-group class="talent-nav-search-content" compact>
            <a-select
                default-value="1"
                size="large"
                class="talent-nav-search-type"
                :value="searchSe"
                @change="search"
            >
              <a-select-option value="1">
                服务
              </a-select-option>
              <a-select-option value="2">
                成果
              </a-select-option>
              <a-select-option value="3">
                需求
              </a-select-option>
              <a-select-option value="4">
                专家
              </a-select-option>
              <a-select-option value="5">
                政策
              </a-select-option>
            </a-select>
            <a-input-search
                class="talent-nav-search-input"
                placeholder="请输入要搜索的内容"
                enter-button="搜索"
                size="large"
                v-model="key"
                @search="onSearch"
            />
          </a-input-group>
          <div v-if="isUserType==='1'&&isPermissions==='1'" class="talent-btn-bg talent-push-btn" @click="addDemand()">
            发布需求
          </div>
        </div>
      </div>
      <div class="talent-nav-bar">
        <div class="talent-nav-bar-list">
          <div
              v-if="activeNav === 'home'"
              class="talent-nav-bar-item talent-btn-bg talent-btn-seve"
          >
            服务市场
          </div>
          <div  v-for="(item, index) in navAry"
                :key="index">
              <router-link
                  v-if="item.url !== 'contract'&& item.url!=='know'"
                  :to="{ name: item.url}"
              ><span
                  v-show="!(item.url==='home'&& activeNav === 'home')"
                  :class="['talent-nav-bar-item', { active: isActive(item.pages)},{demand:item.pages.includes('demand')&&demandtype}]">
            {{ item.name }}
          </span>
              </router-link>
              <span
                  v-if="item.url==='contract'"
                  :class="['talent-nav-bar-item', { active: isActive(item.pages)},{demand:item.pages.includes('demand')&&demandtype}]">
              <a  class="like-icp" style="color:#FFFFFF;"
                  :href="contractUrl" target="_blank">{{ item.name }}
              </a>
            </span>
              <span
                  v-if="item.url==='know'"
                  :class="['talent-nav-bar-item', { active: isActive(item.pages)},{demand:item.pages.includes('demand')&&demandtype}]">
              <a  class="like-icp" style="color:#FFFFFF;"
                  :href="knowUrl" target="_blank">{{ item.name }}
              </a>
            </span>
          </div>

        </div>
      </div>
    </div>
    <div class="talent-content">
      <!--      <keep-alive :exclude="blackList">-->
      <router-view ref="aaa"></router-view>
      <!--      </keep-alive>-->
    </div>
    <div class="talent-footer">指导单位：湖南省科技厅/株洲市科技局/株洲高新区产业局&nbsp;&nbsp;&nbsp;&nbsp;建设运营单位：湖南高科园创园区管理服务有限公司<br/>
      技术支持：株洲高科撩园信息科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <a class="like-icp" style="color:#FFFFFF;"
       href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank"><u>湘ICP备15020547号-2</u></a>
    </div>

    <login ref="login" @fetchLoginBtn="fetchLoginBtn"></login>
    <Back ref="Back"></Back>
  </div>
</template>
<script>
import login from '../views/components/login'
import Back from '../views/components/back'
import {getToken, getType,getPermissions} from "../utils/auth";
import {detail} from "../api/company/config";

export default {
  name: "Layout",
  components: {login,Back},
  watch: {
    $route() {
      this.activeNav = this.$route.name;
    },
  },
  data() {
    return {
      contractUrl:'https://www.baidu.com/',
      knowUrl:'https://www.baidu.com/',
      isShowCenter:true,
      key:'',
      searchSe:'1',
      isPermissions:null,
      isUserType: null,
      ifLogin: false,
      demandtype: false,
      activeNav: "home",
      navAry: [
        {name: "首页", url: "home", pages: ["home"]},
        {name: "创新政策", url: "gov", pages: ["gov"]},
        {name: "科技活动", url: "science", pages: ["science"]},
        {name: "技术合同登记", url: "contract", pages: ["contract"]},
        {name: "知识产权分中心", url: "know", pages: ["know"]},
        {name: "需求中心", url: "demand", pages: ["demand"]},
        // {name: "个人中心", url: "personalCenter", pages: ['personalCenter']}
      ],
      blackList: ["enterprise"],
    };
  },
  methods: {
    Back(index){
      if(index===1){
        this.$router.push({
          path: '/personalCenter',
          query: {
            index: '11',
          }
        })
      } else if(index===2){
        this.$router.push(
            {
              path: '/personalCenter',
              query:{
                index:'1'
              }
            }
            )
      }else{
        this.$refs.Back.show()
      }
      if(index===1||index===2){
        this.$refs.aaa.init()
      }
    },
    search(a){
      this.searchSe=a;
    },
    addDemand() {
      if (this.isUserType === null) {
        this.$message.success("请登陆")
        return
      }
      this.demandtype = true;
      this.$router.push('/demand/add')
    },
    onSearch(key) {
      //console.log(this.searchSe)
      if(this.searchSe==='1'){this.$router.push({
        path: '/service',
        query: {
          key: key,
      }
      })}
      else if(this.searchSe==='2'){this.$router.push({
            path: '/specialty',
            query: {
              key: key,
            }
          })}
      else if(this.searchSe==='3'){this.$router.push(
        {
          path: '/demand/demand',
              query:{
                key: key,
              }
        }
      )}
      else if(this.searchSe==='4'){this.$router.push(
        {
          path: '/expert',
              query:{
          key: key,
        }
        }
        )}
      else if(this.searchSe==='5'){this.$router.push(
        {
          path: '/gov/gov',
              query:{
          key: key,
        }
        }
        )}
      this.$refs.aaa.init()
      this.$refs.aaa.current=null;
      /*this.key='';
      this.searchSe='1';*/
    },
    isActive(pages) {
      if (this.activeNav !== "add" && this.activeNav !== "demand") {
        this.demandtype = false;
      }
      return this.activeNav === "home" ? false : pages.includes(this.activeNav);
    },
    login() {
      this.$refs.login.show("0")
    },
    registered() {
      /*注册*/
      this.$router.push('/registered')
    },
    fetchLoginBtn() {
      if (getToken() !== undefined) {
        //console.log(getToken())
        //console.log('getToken()')
        this.ifLogin = true
        this.init();
      }
    },
    init(){
      this.isPermissions=getPermissions()
      //console.log(getPermissions(),"4444444444444444")
      //console.log(getType(), "===========================")
      this.isUserType = getType();
      //console.log(this.isUserType, "2===========================")
      detail({"id":1}).then(res=>{
        this.contractUrl = res.data.value
      })
      detail({"id":2}).then(res=>{
        this.knowUrl = res.data.value
      })
    }
  },
  mounted() {

    this.init();
    this.fetchLoginBtn()
    this.activeNav = this.$route.name;
    // this.personalCenter()
  },
};
</script>

<style lang="scss" scope>
.talent-main {
  position: relative;
  min-height: 100vh;

  .talent-nav {
    position: relative;

    .talent-bg {
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      height: 317px;
    }

    .talent-nav-content {
      width: 1200px;
      margin: 0 auto;
      z-index: 2;
      padding-top: 20px;

      .talent-nav-top {
        display: flex;
        justify-content: space-between;
        color: #ffffff;

        .talent-nav-logo {
          font-size: 24px;
          font-weight: bold;

          img {
            padding-right: 20px;
          }
        }

        .talent-nav-btns {
          padding-left: 20px;
          cursor: pointer;
          user-select: none;
          width: 120px;
          height: 40px;
          border: 1px solid #ffffff;
          border-radius: 20px ;
          text-align: center ;
          line-height: 36px ;
          font-size: 16px ;
          background-color: rgba(209,209,209,0.05);
          //opacity:0.2;
          span{ color: #FFFFFF}
        }
      }

      .talent-nav-search {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 80px;

        .talent-nav-search-content {
          width: 550px;

          .talent-nav-search-type {
            width: 103px;
            background: #f6f6f6;
            border-radius: 0;
          }

          .talent-nav-search-input {
            width: 446px;

            .ant-btn {
              background: #ff9a02;
              border-color: #ff9a02;
              border-radius: 0;
            }
          }
        }

        .talent-push-btn {
          display: inline-block;
          width: 130px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          user-select: none;
          font-size: 16px;
          cursor: pointer;
          margin-left: 10px;
        }
      }
    }

    .talent-nav-bar {
      width: 100%;
      height: 65px;
      background: rgb(7, 95, 234);
      margin-top: 107px;

      .talent-nav-bar-list {
        width: 1200px;
        margin: 0 auto;
        height: 100%;
        display: flex;

        .active {
          background: #044fc6;
        }

        .demand {
          background: #044fc6;
        }

        .talent-btn-bg {
          font-size: 22px;
          width: 300px;
          text-align: center;
          padding: 0;
        }

        .talent-nav-bar-item {
          padding: 20px 35px;
          font-size: 18px;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
          margin-right: 10px;
          line-height: 65px;
        }

        //.talent-nav-bar-item[href="#/home"] {
        //  padding: 20px 170px;
        //  position: relative;
        //  left: 60px
        //}
      }
    }
  }

  .talent-content {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 389px);
  }

  .talent-footer {
    width: 100%;
    height: 100px;
    background: #275db3;
    color: #ffffff;
    margin-top: 30px;
    position: relative;
    bottom: 0;
  }

  .talent-no-index {
    background: linear-gradient(0deg, #e0ebfd, #ffffff);

    .talent-bg {
      display: none;
    }

    .talent-nav-top {
      color: #333333 !important;

      .talent-nav-btns {
        background: #f6f6f6;
        color: #666666;
        border: 1px solid #dddddd !important;
        span{color: #666666 !important;}
      }
    }

    .talent-nav-search {
      margin-top: 30px !important;
    }

    .talent-nav-bar {
      margin-top: 34px;
    }
  }

  .talent-btn-seve {
    //margin-left: 100px;
    font-size: 22px;
    line-height: unset !important;
  }
}
</style>
<style lang="scss">
.ant-popover-placement-bottom{
.button3{
  width: 100px !important;
  border-width: 0px !important;
}
.ant-popover-message > .anticon {
  display: none;
}
.ant-popover-buttons {
  display: none;
}
.ant-popover-inner-content {
  padding: 0px 0px !important;
  position: relative;
  right: 10px;
  color: rgba(0, 0, 0, 0.65);
}
.ant-popover-content{
  margin-left: 8px;
}
.ant-btn:hover, .ant-btn:focus {
  color: #FF9A02 !important;
/*  background-color: #fff;
  border-color: #FF9A02;*/
}
}
</style>
