<template>
  <div class="g-nav">
    <div class="g-nav-title">
      {{ name }}
    </div>
    <div
        v-for="item in list"
        :key="item.id"
    >
      <div   :class="['g-nav-item', { active: act === item.id}]" @click="selNav(item)">{{item.name}}
        <a-icon :type="item.hasChildren?'down':'right'" v-show="act === item.id"/>

      </div>
      <div class="nav-childrens" v-show="act === 1&&showChildren" v-if="item.hasChildren">
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(1)" :class="['g-nav-sub-item',{ activeLi: actA ===1}]">业务征集</div>
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(2)" :class="['g-nav-sub-item',{ activeLi: actA ===2}]">我的发布</div>
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(8)" :class="['g-nav-sub-item',{ activeLi: actA ===8}]">需求申请</div>
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(3)" :class="['g-nav-sub-item',{ activeLi: actA ===3}]">技术申请</div>
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(4)" :class="['g-nav-sub-item',{ activeLi: actA ===4}]">资金申请</div>
        <div  @click="selNavA(5)" :class="['g-nav-sub-item',{ activeLi: actA ===5}]">服务申请</div>
        <div v-if="$store.state.user.isPermissions()==='1'" @click="selNavA(6)" :class="['g-nav-sub-item',{ activeLi: actA ===6}]">专家关注</div>
        <div  @click="selNavA(7)" :class="['g-nav-sub-item',{ activeLi: actA ===7}]">活动报名</div>
      </div>

    </div>
  </div>
</template>
<script>


export default {
  name: "LeftNav",
  props: {
    list: {
      type: Array,
      default: () => {
        return [
          {name: "默认1", id: 0},
          {name: "默认2", id: 1},
          {name: "默认3", id: 2},
        ];
      },
    },
    name: {
      type: String,
      default: "默认",
    },
    active: {
      type: Object,
      default: null,
    },
    defaultNav: Number,
    hasChildren: Boolean
  },
  data() {
    return {
      type: null,
      showChildren: false,
      act: null,
      actA: null
    };
  },
  watch: {
    active() {
      //console.log("aaa")
      this.selNav(this.active);
    },
    defaultNav() {
      // this.act = this.defaultNav;
      this.$set(this, "act", this.defaultNav)
      //console.log('改变act', this.act)
    }
  },
  methods: {
    selNav(item) {
      //console.log("导航切换 " + item.name)
      // this.showChildren = -1
      this.act = item.id;
      if(item.hasChildren){
        this.showChildren = !this.showChildren;
        return;
      }else{this.showChildren=false}
      this.$emit("changeNav", item);
    },
    selNavA(item) {
      //console.log("管理中心导航切换 " + item)
      this.actA = item;
      this.$emit("changeNavA", item);
    },
  },
  mounted() {
    // this.type =this.$store.state.user.type();
    if (this.active !== null) this.act = this.active.id;
  },
};
</script>
<style lang="scss" scope>
.g-nav {
  width: 260px;
  border: 1px solid #efefef;
  border-bottom: none;
  overflow: hidden;

  .g-nav-item{
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #efefef;
    font-size: 16px;
    line-height: 59px;
    padding-left: 40px;
    color: #333333;
    cursor: pointer;
    position: relative;

    .anticon {
      position: absolute;
      right: 40px;
      top: 20px;
    }

  }
  .g-nav-title {
    padding-left: 40px;
    height: 55px;
    background: #075fea;
    font-size: 18px;
    font-weight: bold;
    line-height: 54px;
    color: #ffffff;
    cursor: inherit;
  }

  .active {
    background: #f9f9f9;
  }


  .nav-childrens{
    border-bottom: 1px solid #efefef;
    .g-nav-sub-item{
      font-size: 16px;
      cursor: pointer;
      user-select: none;
      height: 46px;
      list-style: none;
      line-height: 46px;
      padding-left: 80px;
      &:hover{
        color: #044fc6;
      }
    }
    .activeLi {
      color: #044fc6;
    }
    .activeA {
      height: 400px;
    }
  }



}
</style>
