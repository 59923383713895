import axios from 'axios'
import {message, Modal} from 'ant-design-vue';
import {getToken, removeToken} from '@/utils/auth'
import router from "../router";

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000, // request timeout
    method: 'POST',
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type":"application/json"
    }
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        config.headers['token'] = getToken()
        return config
    },
    error => {
        // do something with request error
        //console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 10002) {
                Modal.confirm({
                    title: '温馨提示',
                    content: '登录状态已过期,请重新登录',
                    onOk: () => {
                        router.push({path: '/home'})
                        removeToken()
                        // location.reload()
                    }
                });
            } else {
                message.error(res.msg)
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        message.error(error.message)
        return Promise.reject(error)
    }
)

export default service
