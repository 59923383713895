<template>
  <div class="forget">
    <a-modal @cancel="turnOff" v-model="visible" title="忘记密码" width="500px" class="login-form" :footer="null"
             :centered="true">
      <a-form-model ref="form" :model="form" :rules="rules" :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="form.phone" max-length="11" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item label="验证码" required>
          <div style="display: flex;height: 20px;">
            <div style="margin-right: 5px">
              <a-form-model-item prop="code">
                <a-input v-model="form.code" style="width: 195px"/>
              </a-form-model-item>
            </div>
            <div>
              <a-button :disabled="ifShow" @click="sendCode" style="background: #075fea;color:#fff;bottom: 2px;">
                <span v-show="ifShow">{{ count }}s</span>
                <span v-show="!ifShow">发送验证码</span>
              </a-button>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="password">
          <a-input v-model="form.password" type="password" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="cPassword">
          <a-input v-model="form.cPassword" type="password" style="width: 300px;"/>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" style="margin-left: 100px;bottom: 10px;">
          <a-button style="background:#075fea " type="primary" @click="turnOff">
            返回
          </a-button>
          <a-button style="margin-left: 10px;" @click="onSubmit">
            提交
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import {forgetPass, sendAuthCode} from "../../api/user";

export default {
  name: "ForgetPass",
  data() {
    var isPhone =(rule, value, callback) =>{
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value === '' || value === undefined || value == null) {
        this.ifSendAuth = true
        callback(new Error('请输入手机号'));
      } else {
        if ((!reg.test(value)) && value !== '') {
          this.ifSendAuth = true
          callback(new Error('请正确填写手机号'));
        } else {
          this.ifSendAuth = false
          callback();
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      let reg=/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
      if (value === '' || value === null) {
        callback(new Error('请输入密码'));
      } else if(value.length < 6){
        callback(new Error('密码长度最小6位'));
      }else if(!reg.test(value)){
        callback(new Error('密码要同时含有数字和字母'));
      }else {
        callback();
      }
    };
    return {
      ifSendAuth: false,
      timer: null,
      ifShow: false,
      count: null,
      visible: false,
      vCode: null,
      labelCol: {span: 5},
      wrapperCol: {span: 5},
      form: {
        phone: null,
        password: null,
        cPassword: null,
        code: null,
        type: null,
      },
      rules: {
        phone: [{validator: isPhone, trigger: 'blur'}],
        password: [{required: true,validator: validatePass, trigger: 'blur'}],
        cPassword: [{required: true,validator: validatePass, trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
      }
    }
  },
  methods: {
    sendCode() {
      if (this.form.phone === null) {
        this.$message.warning('手机号码不能为空')
        return
      }
      if (this.ifSendAuth){
        this.$message.warning('请输入正确的手机号码')
        return;
      }
      if (!this.ifShow){
        let param = {phone: this.form.phone, type: this.form.type}
        sendAuthCode(param).then(res => {
          this.$message.success(res.msg)
          if(res.code === 200){
            const TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.ifShow = true;
            this.timer = setInterval(() =>{
              if (this.count > 0 && this.count <=TIME_COUNT){
                this.count--;
              }else {
                this.ifShow = false
                clearInterval(this.timer)
                this.timer = null
              }
            },1000)
          }
        })
      }
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.password !== this.form.cPassword) {
            this.form.cPassword = null
            this.$message.warning('密码不一致，请重新输入');
            return
          }
          forgetPass(this.form).then(res => {
            this.$refs.form.resetFields()
            this.visible = false
            this.$message.success(res.msg)
          })
        }
      })
    },
    show(type) {
      this.form.type = type
      this.visible = true
    },
    turnOff() {
      this.visible = false
      this.$refs.form.resetFields()
    },
  }
}
</script>

<style>
.forget .ant-modal-title {
  margin-left: 180px !important;
  font-size: 22px;
}
</style>
