import Vue from 'vue'
import App from './App.vue'
import store from './store'

import router from './router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './style/index.css';
import LeftNav from './components/LeftNav';
Vue.use(Antd);
Vue.component("t-nav",LeftNav)
Vue.config.productionTip = false
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
