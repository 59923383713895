import Cookies from 'js-cookie'

const TokenKey = 'user_t'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function setPermissions(permissions) {
  return Cookies.set("Permissions", permissions)
}
export function getPermissions() {
  return Cookies.get("Permissions")
}
export function setType(type) {
  return Cookies.set('type', type)
}

export function getType() {
  return Cookies.get('type')
}

export  function   removePermissions() {

  return Cookies.remove('Permissions')
}

export function removeToken() {
  Cookies.remove('type')
  return Cookies.remove(TokenKey)
}
