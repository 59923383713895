import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/user/userLogin',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getSubject() {
    return request({
        url: '/user/getSubject',
        method: 'get'
    })
}

export function forgetPass(data) {
    return request({
        url: '/user/forgetPass',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function sendAuthCode(data) {
    return request({
        url: '/user/sendAuthCode',
        params:data,
        method: 'get'
    })
}

export function userDetails() {
    return request({
        url: '/user/getDetails',
        method: 'get'
    })
}

export function institutionsDetails() {
    return request({
        url: '/institutions/getDetail',
        method: 'get'
    })
}

export function singleRegister(data) {
    return request({
        url: '/user/singleRegister',
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function userUpdate(data) {
    return request({
        url: '/user/update',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function institutionsUpdate(data) {
    return request({
        url: '/institutions/update',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function resetPassword(data) {
    return request({
        url: '/user/resetPassword',
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function getInfo(token) {
    return request({
        url: '/vue-admin-template/user/info',
        method: 'get',
        params: {token}
    })
}

export function logout() {
    return request({
        url: '/user/user/logout',
        method: 'post'
    })
}
