import {login, logout} from '@/api/user'
import {getToken, setToken, removeToken,setType,getType} from '@/utils/auth'
import {resetRouter} from '@/router'
import {getPermissions} from "../../utils/auth";

const getDefaultState = () => {
    return {
        isPermissions: getPermissions,
        token: getToken(),
        // name: '',
        type: getType
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_TYPE: (state, type) => {
        state.type = type
    }
}

const actions = {
    // user login
    login({commit}, userInfo) {
        const {email, password, type} = userInfo
        return new Promise((resolve, reject) => {
            login({email: email.trim(), password: password, type: type}).then(response => {
                const {data} = response
                commit('SET_TOKEN', data.token)
                commit('SET_TYPE', type)
                setToken(data.token)
                setType(type)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    // getInfo({commit, state}) {
    //     return new Promise((resolve, reject) => {
    //         getInfo(state.token).then(response => {
    //             const {data} = response
    //
    //             if (!data) {
    //                 return reject('Verification failed, please Login again.')
    //             }
    //
    //             const {name, avatar} = data
    //
    //             commit('SET_NAME', name)
    //             commit('SET_AVATAR', avatar)
    //             resolve(data)
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // },

    // user logout
    logout({commit, state}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                removeToken() // must remove  token  first
                resetRouter()
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({commit}) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

